<template>
  <div>
    <div>
      <div class="page-title">
        <h1>Markets</h1>
      </div>

      <b-card class="market-card" no-body>
        <b-tabs card>
          <b-tab title="Zoombie NFTs">
            <h3>Zoombie NFTs</h3>
            <h5>
              ERC-721 NFT tokens are supported on the following auction
              marketplaces:<br />
            </h5>
            Zoombies NFT Contract - 0x08716e418e68564C96b68192E985762740728018
            <br /><br />

           <b-row>
              <b-col></b-col>
              <b-col>
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://market.zoombies.world/"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          class="markets-logo"
                          src="https://zoombies.world/images/zoombies_coin.svg"
                          align="absmiddle"
                        />
                        <img style="float:right; max-width:42px"
                          src="https://market.zoombies.world/static/media/mynew.da1b3c41.png"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>ZOOM Auction Market</h2>
                        <p>
                          No Commission timed auction NFT market featuring advanced Zoombies and Zoom token support.
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
                </b-col>
                <b-col></b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://raregems.io/moonriver/zoombies?for_sale=1"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          class="markets-logo"
                          src="https://s.raregems.io/45/img/diamond.svg"
                          align="absmiddle"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>RareGems NFT Market</h2>
                        <p>
                          Offers a very Zoombies NFT collection specific search
                          and filter experience
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://tofunft.com/collection/zoombies/items"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          class="markets-logo"
                          src="https://docs.tofunft.com/images/logos/light_round.svg"
                          align="absmiddle"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>tofuNFT - MOVR NFT Marketplace</h2>
                        <p>
                          tofuNFT is a fully featured decentralized marketplace
                          for buying, selling and trading NFTs, created by
                          SCV.Finance, and deployed on multiple blockchains.
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://moonbeans.io/#/collections/zoombies"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          class="markets-logo"
                          src="https://moonbeans.io/images/logo/logo.png"
                          align="absmiddle"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>Moonbeans - MOVR NFT Marketplace</h2>
                        <p>
                          Moonbeans provides a detailed and user friendly
                          Zoombies search, buy and sell experience.
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://activity.treasureland.market/collection?contract=0x08716e418e68564c96b68192e985762740728018&chainId=1285"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          class="markets-logo"
                          src="https://activity.treasureland.market/imgs/logo/TLlogo_b.svg"
                          align="absmiddle"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>Treasureland</h2>
                        <p>
                          Treasureland is a cross-chain NFT platform for NFT issuance, trading, auction and tailored in-shop services.
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
              </b-col>
            </b-row>
            <br />
          </b-tab>

          <b-tab title="ZOOM Token">
            <h3>
              ZOOM
              <img
                class="czxp-logo"
                src="@/assets/zoomTokenCoin.svg"
                alt="zoom coin"
              />
              token
            </h3>
            <h5>
              The ERC-20 compliant ZOOM Moonriver tokens are supported on the
              following token exchanges:<br />
            </h5>
            ZOOM token Contract - 0x8bd5180Ccdd7AE4aF832c8C03e21Ce8484A128d4
            <br /><br />

            <b-row>
              <b-col sm="12" lg="6">
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://app.moonlit.finance/?swapPair=MOVR_ZOOM"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          src="https://app.moonlit.finance/_nuxt/img/moonriver_logo.c54c2cc.svg"
                          align="absmiddle"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>Moonlit Finance - ZOOM-MOVR Yield Farm</h2>
                        <p>
                          Stake your Moonlit Liquidity Provider MLP tokens to
                          earn reward token such as MLIT
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
              </b-col>

              <!-- b-col sm="12" lg="6">
                <b-card class="market" no-body>
                  <a
                    class="market-btn"
                    href="https://app.sushi.com/swap?inputCurrency=&outputCurrency=0x8bd5180Ccdd7AE4aF832c8C03e21Ce8484A128d4"
                    target="_blank"
                  >
                    <div class="market-info">
                      <div class="">
                        <img
                          src="https://zoombies.world/images/sushi_swap_logo.webp"
                          align="absmiddle"
                        />
                      </div>
                      <div class="market-detail">
                        <h2>SushiSwap - ZOOM-MOVR swap pool</h2>
                        <p>
                          Sushi is the home of DeFi. Our community is building a
                          comprehensive, decentralized trading platform for the
                          future of finance. Join us!
                        </p>
                      </div>
                    </div>
                  </a>
                </b-card>
              </b-col-->

            </b-row>

            <br />
          </b-tab>
        </b-tabs>
      </b-card>

      <p>
        If you have an NFT market on Moonriver and would like to feature
        Zoombies NFT World Cards, you can use the contract TokenURI endpoints
        for automated metadata consumption. We currently follow the OpenSea
        metadata standard. For inquires
        <a
          href="mailto:contactus@zoombies.world?subject=NFT market integrations"
          >please contact us</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { BCard, BTabs, BTab, BRow, BCol } from "bootstrap-vue";

export default {
  name: "MarketContent",
  components: {
    BCard,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  data() {
    return {
      msg: "Here we go, here we go",
    };
  },
};
</script>
<style lang="scss">
.page-title {
  margin-top: 36px;
}
#treasureland-logo {
  height: 45px;
}

.uni-logo {
  height: 20px;
  margin-bottom: 10px;
}
.czxp-logo {
  width: 30px;
  height: 30px;
}

a.market-btn {
  color: #fff;
  background-color: #304f4f;
}

.market-btn:hover {
  color: #000;
  background-color: #304f4f;
  padding: 10px;
  max-height: fit-content;
  text-decoration: none;
  border: 1px solid #7df2f4;
}
a:hover {
  text-decoration: none;
}
.market-btn {
  color: #000;
  background-color: #304f4f;
  padding: 10px;
  text-decoration: none;
  max-height: fit-content;
  border: 1px solid white;
}
h3 {
  margin-bottom: 1em;
}

.nav-tabs .nav-link {
  background-color: darkslateblue;
  color: grey;
  border: 1px solid black;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: darkslateblue;
  color: white;
  border: 1px solid white;
}

.tab-content > .active {
  border: 1px solid grey;
}

.market-card {
  color: white;
  background-color: darkslateblue;
}

.markets-wrapper {
  margin-top: 24px;
}

.market-info {
  display: flex;
  flex-direction: column;
  background-color: darkslategrey;
}

.market-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  img {
    width: 200px;
    height: 90px;
  }
}

.market {
  margin-top: 16px;
}

.market-detail {
  margin-left: 20px;

  h2 {
    font-size: 18px;
  }
}

.oneinch-exchange {
  background-color: lightgray;
}

.markets-logo {
  height: 40px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1300px) {
  .markets-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 16px;
  }

  .market-img {
    margin-bottom: 0;
    img {
      width: 120px;
      height: 80px;
    }
  }

  .market {
    width: 550px;
    justify-self: center;

    .market-btn {
      height: 100%;

      .market-info {
        height: 100%;
        flex-direction: row;
      }
    }
  }

  .markets-logo {
    height: 40px;
    margin-bottom: 10px;
  }
}
</style>
